import { EnumToArray } from '../../../utils/enumToArray';
enum State {
  Disabled = 'disabled',
  Resting = 'resting',
  Loading = 'loading',
  Selected = 'selected',
}
enum AvatarType {
  Petz = 'petz',
  External = 'external',
}
enum AvatarSize {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}
export const PTZAvatarConfigList = {
  StateList: EnumToArray([State]),
  AvatarType: EnumToArray([AvatarType]),
  AvatarSize: EnumToArray([AvatarSize]),
};
export const PTZAvatarConfig = {
  State,
  AvatarType,
  AvatarSize,
};

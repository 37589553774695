$colors: (
  'primary-brand': $seres-color-primary-brand,
  'primary-dark': $seres-color-primary-dark,
  'primary-light': $seres-color-primary-light,
  'secondary-brand': $seres-color-secondary-brand,
  'secondary-dark': $seres-color-secondary-dark,
  'secondary-light': $seres-color-secondary-light,
  'neutral-black': $seres-color-neutral-black,
  'neutral-dark': $seres-color-neutral-dark,
  'neutral-darker-accent': $seres-color-neutral-darker-accent,
  'neutral-mid': $seres-color-neutral-mid,
  'neutral-light': $seres-color-neutral-light,
  'neutral-lighter-accent': $seres-color-neutral-lighter-accent,
  'neutral-white': $seres-color-neutral-white,
  'sup-yellow-darker-accent': $seres-color-sup-yellow-darker-accent,
  'sup-yellow-dark': $seres-color-sup-yellow-dark,
  'sup-yellow-mid': $seres-color-sup-yellow-mid,
  'sup-yellow-light': $seres-color-sup-yellow-light,
  'sup-green-darker-accent': $seres-color-sup-green-darker-accent,
  'sup-green-dark': $seres-color-sup-green-dark,
  'sup-green-mid': $seres-color-sup-green-mid,
  'sup-green-light': $seres-color-sup-green-light,
  'sup-red-darker-accent': $seres-color-sup-red-darker-accent,
  'sup-red-dark': $seres-color-sup-red-dark,
  'sup-red-mid': $seres-color-sup-red-mid,
  'sup-red-light': $seres-color-sup-red-light,
  'chart-purple-dark': $seres-color-chart-purple-dark,
  'chart-purple-mid': $seres-color-chart-purple-mid,
  'chart-purple-light': $seres-color-chart-purple-light,
  'chart-pink-dark': $seres-color-chart-pink-dark,
  'chart-pink-mid': $seres-color-chart-pink-mid,
  'chart-pink-light': $seres-color-chart-pink-light,
  'chart-blue-dark': $seres-color-chart-blue-dark,
  'chart-blue-mid': $seres-color-chart-blue-mid,
  'chart-blue-light': $seres-color-chart-blue-light,
  'chart-orange-dark': $seres-color-chart-orange-dark,
  'chart-orange-mid': $seres-color-chart-orange-mid,
  'chart-orange-light': $seres-color-chart-orange-light,
);

ptz-avatar {
  .disabled {
    border: $petz-border-sm solid $petz-color-neutral-mid !important;
    .ptz-avatar-wrapper {
      background-color: $petz-color-neutral-light;
      display: flex;
      justify-content: center;
      align-items: stretch;
    }
  }
  .srs-avatar {
    border: $seres-border-sm solid $seres-color-primary-brand;
  }
  .ptz-avatar-label {
    margin: 0;
    font-weight: $petz-font-weight-bold;
    font-size: $petz-font-size-md;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    color: $petz-color-neutral-black;

    @each $id, $color in $colors {
      &-#{$id} {
        color: $color;
      }
    }
  }
  .ptz-avatar-lg {
    width: 96px;
    height: 96px;
    .ptz-avatar-wrapper,
    .ptz-avatar-spinner {
      width: 88px;
      height: 88px;
    }
    .ptz-avatar-label {
      font-size: $petz-font-size-2xl;
    }
    .petz-avatar {
      img {
        min-width: 34px;
        max-width: 48px;
        min-height: 39px;
        max-height: 58px;
      }
    }
    .icon-checked {
      i {
        font-size: 2.5rem;
      }
    }
  }
  .ptz-avatar-md {
    width: 64px;
    height: 64px;
    .ptz-avatar-wrapper,
    .ptz-avatar-spinner {
      width: 60px;
      height: 60px;
    }
    .ptz-avatar-label {
      font-size: $petz-font-size-md;
    }
    .petz-avatar {
      img {
        min-width: 26px;
        max-width: 32px;
        min-height: 32px;
        max-height: 44px;
      }
    }
    .icon-checked {
      i {
        font-size: $petz-font-size-2xl;
      }
    }
  }
  .ptz-avatar-sm {
    width: 48px;
    height: 48px;
    .ptz-avatar-wrapper,
    .ptz-avatar-spinner {
      width: 44px;
      height: 44px;
    }
    .ptz-avatar-label {
      font-size: $petz-font-size-xs;
    }
    .petz-avatar {
      img {
        min-width: 16px;
        max-width: 24px;
        min-height: 19px;
        max-height: 27px;
      }
    }
    .icon-checked {
      i {
        font-size: $petz-font-size-xs;
      }
    }
  }
}
.ptz-avatar {
  border: $petz-border-sm solid $petz-color-primary-brand;
  background-color: $petz-color-neutral-white;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .petz-avatar {
    img {
      object-fit: contain;
    }
  }
  .external-avatar {
    img {
      width: 100%;
      height: 100%;
      border-radius: 100px;
      object-fit: cover;
    }
  }
  img {
    display: flex;
    align-self: center;
  }
  &-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 100px;
    background-color: $petz-color-neutral-lighter-accent;
    border: none;

    @each $id, $color in $colors {
      &-#{$id} {
        background-color: $color;
      }
    }

    ptz-icon {
      display: flex;
    }
  }
  &-spinner {
    position: absolute;
    justify-content: center;
    border-radius: 100px;
    border: none;
    align-items: center;
    opacity: 0.32;
    display: flex;
  }
  &-icons {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      .ptz-spinner-background {
        stroke: $seres-color-neutral-white !important;
        opacity: 0.8;
      }
    }
    .srs-spinner {
      svg {
        .ptz-spinner-circle {
          stroke: $seres-color-primary-brand !important;
        }
      }
    }
    .ptz-spinner {
      svg {
        .ptz-spinner-circle {
          stroke: #6394c5 !important;
        }
      }
    }
  }
  .spinner-srs {
    background-color: $seres-color-primary-dark;
  }
  .spinner-ptz {
    background-color: $petz-color-primary-dark;
  }
}

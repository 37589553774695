import { PTZTokensTypes } from '@petz/ds-tokens/petz';
import { Component, Host, Prop, h } from '@stencil/core';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZAvatarConfig } from './types/ptz-avatar.enums';
import { PTZAvatarTypes } from './types/ptz-avatar.types';
@Component({
  tag: `ptz-avatar`,
  styleUrl: 'ptz-avatar.scss',
  shadow: false,
})
export class PTZAvatar {
  /** Preenche label abreviado */
  @Prop() label: string

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Propriedade da imagem */
  @Prop() image?: string

  /** Propriedade para controlar o estado */
  @Prop() state: PTZAvatarTypes.StateList = PTZAvatarConfig.State.Resting

  /** Preenche id padrão */
  @Prop() avatarId: string

  /** Propriedade para controlar se a imagem é da Petz ou externo */
  @Prop() avatarType?: PTZAvatarTypes.AvatarType = PTZAvatarConfig.AvatarType.Petz

  /** Propriedade para controlar o tamanho */
  @Prop() avatarSize?: PTZAvatarTypes.AvatarSize = PTZAvatarConfig.AvatarSize.Lg

  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Propriedade para controlar a cor do texto */
  @Prop() textColor?: PTZTokensTypes.Color = 'neutral-black'

  /** Propriedade para controlar a cor de fundo */
  @Prop() bgColor?: PTZTokensTypes.Color = 'neutral-lighter-accent'

  /** Propriedade para controlar o avatar ativo */
  @Prop() active: boolean = false

  /** Propriedade para controlar o loading */
  @Prop() isLoading: boolean = false

  /** Propriedade para controlar o nome do ícone */
  @Prop() iconName?: PTZIconTypes.Name

  /** Propriedade para controlar a variante do ícone */
  @Prop() iconVariant?: PTZIconTypes.Variant

  /** Propriedade para controlar a cor do ícone */
  @Prop() iconColor?: PTZTokensTypes.Color

  /** Propriedade para controlar a cor do ícone */
  @Prop() iconSize?: PTZIconTypes.Size

  componentDidLoad() {
    const avatar = document.getElementById(`spinner-${this.avatarId}`);

    if (this.active && (this.state === 'resting' || this.state === 'disabled')) {
      avatar.style.visibility = 'hidden';
    }
  }

  private acronym(label: string) {
    const name = label.split(' ');
    const last = name.length - 1;

    if (name[last] && name.length >= 2) {
      return (name[0][0] + name[last][0]).toUpperCase();
    }

    return label.substring(0,2).toUpperCase();
  }

  render() {

    const avatar = () => {
      if (this.label) {
        return <p class={`ptz-avatar-label ptz-avatar-label-${this.textColor}`}>
          {this.acronym(this.label)}
        </p>
      }

      if (this.image) {
        return  <img src={this.image} alt="avatar" />
      }

      if (this.iconName && this.iconVariant) {
        return <ptz-icon
          name={this.iconName}
          variant={this.iconVariant}
          size={this.iconSize}
          color={this.iconColor}
          brand={this.brand}
        />
      }

      return <p class={`ptz-avatar-label ptz-avatar-label-${this.textColor}`}>--</p>
    }

    const skeleton = {
      sm: 50,
      md: 66,
      lg: 98
    }

    return (
      <Host id={this.avatarId}>
        {this.skeleton ?
        <ptz-skeleton width={skeleton[this.avatarSize]} height={skeleton[this.avatarSize]} rounded="max" /> :
        <div data-testid='avatar' class={`ptz-avatar ${this.state} ${this.brand}-avatar ptz-avatar-${this.avatarSize}`}>
          <div class={`ptz-avatar-wrapper ptz-avatar-wrapper-${this.bgColor} ${this.avatarType}-avatar`}>
            {avatar()}
          </div>
          {this.active && <div class={`ptz-avatar-spinner spinner-${this.brand}`} id={`spinner-${this.avatarId}`}></div>}
          <div class="ptz-avatar-icons">
            {this.isLoading && <ptz-spinner
              size={this.avatarSize}
              appearance="dark"
              id={`ptz-avatar-spinner-${this.avatarId}`}
              class={`${this.brand}-spinner`}
            />}
            {!this.isLoading && this.active && (
              <ptz-icon
                name="check-solid"
                variant="solid"
                size="2xl"
                color="neutral-white"
                brand={this.brand}
                id={`ptz-avatar-check-${this.avatarId}`}
                class="icon-checked"
              />
            )}
          </div>
        </div>}
      </Host>
    );
  }
}
